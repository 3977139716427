import Vue from 'vue'

Vue.component('app-flash', require('@/components/core/Flash.vue').default)
Vue.component('app-dialog', require('@/components/core/Dialog.vue').default)
Vue.component('app-crumbs', require('@/components/core/Crumbs.vue').default)
Vue.component(
  'app-pagination',
  require('@/components/core/Pagination.vue').default
)
Vue.component(
  'base-dialog-form',
  require('@/components/BaseDialogForm').default
)
Vue.component('base-page', require('@/components/BasePage').default)
