import Vault from '@/libs/core/Vault'
import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  {
    path: '/auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    beforeEnter: (to, from, next) => {
      const { token, user } = Vault.peek()
      if (!token) {
        if (user && to.name != 'login') {
          next('/auth/login')
        } else {
          next()
        }
      } else {
        next('/')
      }
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/LoginPage.vue'),
      },
    ],
  },

  {
    path: '',
    component: () => import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (!Vault.take('user')) {
        next('/auth/login')
      } else {
        next()
      }
    },

    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/DashboardPage.vue'),
      },

      //   {
      //     path: '/boundaries',
      //     name: 'nchi.boundaries',
      //     component: () => import('@/views/pages/BoundaryPage.vue')
      //   },

      {
        path: 'nchi/jurisdictions',
        name: 'nchi.jurisdictions',
        component: () => import('@/views/nchi/JurisdictionPage.vue'),
      },

      {
        path: 'silo/resources',
        name: 'silo.resources.index',
        component: () => import('@/views/silo/ResourcePage.vue'),
      },

      {
        path: 'insurance/products',
        name: 'insurance.products',
        component: () => import('@/views/insurance/ProductPage.vue'),
      },

      {
        path: 'insurance/reference-units',
        name: 'insurance.reference-units.index',
        component: () => import('@/views/insurance/ReferenceUnitPage.vue'),
      },

      {
        path: 'insurance/reference-indices',
        name: 'insurance.reference-indices.index',
        component: () => import('@/views/insurance/ReferenceIndexPage.vue'),
      },

      {
        path: 'retail/products',
        name: 'retail.products.index',
        component: () => import('@/views/retail/ProductPage.vue'),
      },

      {
        path: 'ussd/suppliers',
        name: 'ussd.suppliers.index',
        component: () => import('@/views/ussd/SupplierPage.vue'),
      },

      // {
      //   path: 'bidhaa/metrics',
      //   name: 'bidhaa.metrics.index',
      //   component: () => import('@/views/bidhaa/MetricPage.vue')
      // },

      // {
      //   path: 'bidhaa/brands',
      //   name: 'bidhaa.brands.index',
      //   component: () => import('@/views/bidhaa/BrandPage.vue')
      // },

      {
        path: 'paybill/configurations',
        name: 'iam.permissions.index',
        component: () => import('@/views/paybill/PaybillPage.vue')
      },

      {
        path: 'iam/permissions',
        name: 'iam.permissions.index',
        component: () => import('@/views/iam/PermissionPage.vue')
      },

      {
        path: 'iam/suppliers',
        name: 'iam.clients.index',
        component: () => import('@/views/supplier/SupplierPage.vue')
      },

      {
        path: 'iam/farmers',
        name: 'iam.accounts.index',
        component: () => import('@/views/farmer/FarmerPage.vue')
      },

      //   {
      //     path: 'users',
      //     name: 'iam.users.index',
      //     component: () => import('@/views/pages/ReferenceUnitCoefficientPage.vue')
      //   },
    ],
  },

  { path: '*', component: () => import('../views/errors/404.vue') },
]

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
