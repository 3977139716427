import { merge } from 'lodash'

const app = 'forcon:admin'

class Vault {
  keep (info) {
    const data = merge(this.peek(), info)
    localStorage.setItem(app, JSON.stringify(data))
  }

  peek () {
    const data = localStorage.getItem(app)
    return data ? JSON.parse(data) : {}
  }

  take (key) {
    return this.peek()[key]
  }

  seal () {
    localStorage.removeItem(app)
  }

  can (permission) {
    if (!permission) return false
    return this.take('client').user_abilities.includes(permission)
  }
}

export default new Vault()