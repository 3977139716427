<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="$emit('cancel')">Cancel</v-btn>
        <v-btn color="success" text @click="$emit('proceed')">Proceed</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    message: {
      type: String,
      default: 'Are you sure you want to proceed?',
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
