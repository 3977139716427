const serviceRoutes = {
  nchi: [
    { name: 'countries', url: 'countries' },
    { name: 'boundaries', url: 'boundaries' },
    { name: 'jurisdictions', url: 'jurisdictions' },
  ],

  silo: [
    { name: 'farmResourceCategories', url: 'categories' },
    { name: 'farmResources', url: 'resources' },
    { name: 'valueChains', url: 'value-chains' },
    { name: 'valueChainOutputs', url: 'value-chain-outputs' },
  ],

  iam: [
    { name: 'microservices', url: 'microservices' },
    { name: 'permissions', url: 'permissions' },
    { name: 'farmers', url: 'clients/?circles=farmers' },
    { name: 'suppliers', url: 'clients/?circles=suppliers' },
    { name: 'clients', url: 'clients' },
  ],

  insurance: [
    { name: 'insuranceProducts', url: 'products' },
    { name: 'referenceUnits', url: 'reference-units' },
    { name: 'referenceIndices', url: 'reference-indices' },
  ],

  payments: [
    {name: 'configurations', url: 'mpesa/configure-paybill'}
  ],

  retail: [
    { name: 'brands', url: 'brands' },
    { name: 'metrics', url: 'metrics' },
    { name: 'products', url: 'products' },
  ],
}

let routes = []
for (const service in serviceRoutes) {
  routes = routes.concat(
    serviceRoutes[service].map(({ name, url }) => ({
      name,
      url: `${service}/${url}`,
    }))
  )
}

export default routes
